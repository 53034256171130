// Do not edit this file!
// This file was autogenerated by running:
// config_gen.py firebase_config.json
export const config = {
    "apiKey": "AIzaSyCAiFDL8ZbbezwhBEEzP9UCOsVBq8DtI94",
    "appId": "1:680360945571:web:4779e1c3137fc83fbadc16",
    "authDomain": "kb-android-emu.firebaseapp.com",
    "measurementId": "G-VH8XB7FMQT",
    "messagingSenderId": "680360945571",
    "projectId": "kb-android-emu",
    "storageBucket": "kb-android-emu.appspot.com"
};